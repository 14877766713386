import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "row justify-content-md-center mt-4" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "col-12 mb-4" }
const _hoisted_4 = { class: "col-12 col-sm-6 col-xl-4 mb-4" }
const _hoisted_5 = { class: "card border-light shadow-sm" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "small mt-2" }
const _hoisted_10 = { class: "col-12 col-sm-6 col-xl-4 mb-4" }
const _hoisted_11 = { class: "card border-light shadow-sm" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "small mt-2" }
const _hoisted_16 = { class: "col-12 col-sm-6 col-xl-4 mb-4" }
const _hoisted_17 = { class: "card border-light shadow-sm" }
const _hoisted_18 = { class: "card-body" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "small mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Metrics = _resolveComponent("Metrics")!
  const _component_FiatWalletDetails = _resolveComponent("FiatWalletDetails")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_admin = _resolveDirective("admin")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Metrics)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_FiatWalletDetails)
      ])), [
        [_directive_admin, 100]
      ]),
      _withDirectives(_createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0" }, [
                    _createElementVNode("span", { class: "fas fa-credit-card" })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_8, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "" }, [
                    _createElementVNode("h2", { class: "h5" }, "Vouchers"),
                    _createElementVNode("h3", { class: "mb-1" }, "0")
                  ], -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_router_link, { to: "/u/voucher/ordered" }, {
                      default: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createTextVNode(" Go to voucher ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0" }, [
                    _createElementVNode("span", { class: "fas fa-hand-holding-usd" })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_14, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "" }, [
                    _createElementVNode("h2", { class: "h5" }, "Paycode"),
                    _createElementVNode("h3", { class: "mb-1" }, "0")
                  ], -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_router_link, { to: "/u/paycode/created" }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Go to paycode ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col d-flex align-items-center" }, [
                  _createElementVNode("div", { class: "icon icon-shape icon-md icon-shape-blue rounded mr-4 mr-sm-0" }, [
                    _createElementVNode("span", { class: "fas fa-hands" })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_20, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "" }, [
                    _createElementVNode("h2", { class: "h5" }, "Masspayout"),
                    _createElementVNode("h3", { class: "mb-1" }, "0")
                  ], -1)),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_router_link, { to: "/u/masspayout/created" }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Go to Masspayout ")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, false]
      ])
    ])
  ]))
}